<div style="min-height: 88vh; " id="web">
  <form [formGroup]="generalpartEnquiryForm" style="padding: 0%;">
    <mat-card class="basecard ">
    
      <div class="flex pb-2 mb-2  HeadTitle ">

        <div class="mr-2 ml-3">
          <mat-icon class="backIcon" (click)="searchOrder()" *ngIf="backActive" style="cursor: pointer; font-size: 30px; vertical-align: middle; color: white;"
            id="btnBack">keyboard_arrow_left</mat-icon>
          <mat-icon class="titleIcon" style="vertical-align: middle;">handyman</mat-icon>
        </div>

        <div class="mr-2   orderHead">
          <span style="font-size: 22px; font-weight: 500; color: white; letter-spacing: 0.5px;">
            PART INQUIRY
          </span>
        </div>
        <div class="flex-grow-1"></div> <!-- This will push the following span to the right -->
        <div class="mr-2 orderHead" >
            <span>{{ this.utcDate }}</span>
        </div>
      </div>

		<mat-card-content>
			<div class="formgrid p-grid web shadow-6">
				<div class="formgrid p-grid ">
					<div class="field col-6 col-md-3 divbody mt-2">
						<label for="partNumber" id="lblPartNumber" class="formLabels">Part Number</label>
						<input class="form-control m-input" id="partNumber" type="text" (keydown.enter)="submit()" (keyup)="formatPartNumber()"  formControlName="partNumber" pInputText>
            <small id="username2-help" class="errorMessage" *ngIf="showErrorMsg">Please enter Part Number</small>
           </div>

				</div>
		
				<div class="flex justify-content-center">
					<div class="mr-2"> <button pButton id="btnSearch" type="button" label="Search" icon="pi pi-search"
												 class="btn-Search mb-2 mr-2 " (click)="submit()"
												 (keydown.enter)="submit()" (keyup.enter)="submit()"></button></div>
					<div class="mr-2"><button pButton id="btnClear" type="button" label="Clear" icon="pi pi-times"
												class="btn-clear  mb-2" (click)="clear()"></button></div>

        </div>
        
      </div>
      
    </mat-card-content>
    <div class="ml-2 mt-2">
    <small id="username2-help" class="errorMessage" *ngIf="showInvalidError">No Records Found. Please check the search criteria</small>
  </div>  
    <mat-card-content *ngIf="enablePartDetail">
        <div class="flex mat-elevation-z3 ">


          <div class="mr-2 ml-2 mt-1 "><label style="color: #3865a1 ;" class="col-fixed formLabels"
            id="lblpartNumber">Part Number:</label>
          <label  class="col-fixed formLabels mt-1"
            id="lblpartNumber">{{generalpartEnquiryForm.get('partNumber').value | uppercase}}</label>
          </div>
          <div class="mr-2 mt-3 ">
             <span style="background-color: #3865a1 ; border-radius: 6px ; padding: 5px;
             font-size: 14px;color: white; cursor: auto;">{{generalpartEnquiryForm.get('inventoryStatus').value}}</span>
                 <span *ngIf="backOrderVal"
                 style="background-color: #3865a1 ; border-radius: 6px ; padding: 5px;
                  font-size: 14px;color: white; cursor: auto;margin-left: 1%;">B/O</span>
             </div>

          <div  style="width: 33%;margin-left: 50px; " class="mt-2"><label style="color: #3865a1 ;" class="col-fixed formLabels"
            id="lblpartNumber">Restriction: </label>

          <label class="col-fixed formLabels" id="lblpartNumber" *ngIf="restrictionVal">YES</label>
          <label class="col-fixed formLabels" id="lblpartNumber" *ngIf="!restrictionVal">NO</label>
        
        </div>
      </div> 
      <!-- <div class="row mat-elevation-z3 " style="margin-left: 0.5px; margin-right: 0.5px;">
        <div class="col-lg-8 col-md-2 col-sm-6 ">
          <div class="row"  style="justify-content: center;">
           
              <label style="color: #3865a1 ;" class="p-col-fixed formLabels"
            id="lblpartNumber">Part Number:</label>

            
              <label  class="p-col-fixed formLabels"
            id="lblpartNumber">{{generalpartEnquiryForm.get('partNumberVal').value | uppercase}}</label>
           
            <div class="row mt-2" style="justify-content: center;">
              <span style="background-color: #3865a1 ; border-radius: 6px ; padding: 5px;
              font-size: 14px;color: white; cursor: auto;">{{generalpartEnquiryForm.get('inventoryStatus').value}}</span>
                  <span *ngIf="backOrderVal"
                  style="background-color: #3865a1 ; border-radius: 6px ; padding: 5px;
                   font-size: 14px;color: white; cursor: auto;margin-left: 1%;">B/O</span>
  
            </div>

          </div>
         
            
        </div>
       
        <div class="col-lg-4 col-md-2 col-sm-6">
          <div class="row" style="justify-content: center;">
            
              <label style="color: #3865a1 ;" class="p-col-fixed formLabels"
              id="lblpartNumber">Restriction: </label>
            
              <label class="p-col-fixed formLabels" id="lblpartNumber" *ngIf="restrictionVal">YES</label>
              <label class="p-col-fixed formLabels" id="lblpartNumber" *ngIf="!restrictionVal">NO</label>
           

          </div>
            
        </div>
        
       
    </div> -->

      <div class="content  mat-elevation-z4 mt-2">
        <div class="row">
            <div class="col-lg-4 col-md-2 col-sm-6 ">
            <div class="row " >
              <div class="col-lg-7 col-md-2 col-sm-6 " style="text-align: right;">
                <label class="col-fixed formLabels" style="font-weight: 600; font-size: 14px;"
                id="lblpartNumber">Engineering Part</label>
              </div>

              <div class="col-lg-5 col-md-2 col-sm-6 mt-2" >
                <span id="engineeringPartNo" style="font-size: 14px;">
                  {{generalpartEnquiryForm.get('engineeringPartNo').value}}
                </span>
                
              </div>
            </div>
            <div class="row ">
              <div class="col-lg-7 col-md-2 col-sm-6  " style="text-align: right;">
                <label id="lbldescription" class="col-fixed formLabels" style="font-weight: 600; font-size: 14px;">
                  Description</label>
              </div>

              <div class="col-lg-5 col-md-2 col-sm-6 mt-2">
                <span id="engineeringPartNo" style="font-size: 14px;">
                  {{generalpartEnquiryForm.get('desc').value}}
                </span>
                
              </div>
            </div>
            <div class="row ">
              <div class="col-lg-7 col-md-2 col-sm-6  " style="text-align: right;">
                <label id="lblFINIS"  class="col-fixed formLabels" style="font-weight: 600; font-size: 14px;">
                  FINIS</label>
              </div>

              <div class="col-lg-5 col-md-2 col-sm-6  mt-2">
                <span id="engineeringPartNo" style="font-size: 14px;">
                  {{generalpartEnquiryForm.get('finis').value}}
                </span>
                
              </div>
            </div>
            <div class="row ">
              <div class="col-lg-7 col-md-2 col-sm-6  " style="text-align: right;">
                <label id="lblProcurementCode"  class="col-fixed formLabels" style="font-weight: 600; font-size: 14px;">
                  Procurement Code</label>
              </div>

              <div class="col-lg-5 col-md-2 col-sm-6 mt-2 ">
                <span id="engineeringPartNo" style="font-size: 14px;">
                  {{generalpartEnquiryForm.get('procurementCode').value}}
                </span>
                
              </div>
            </div>
            <div class="row ">
              <div class="col-lg-7 col-md-2 col-sm-6  " style="text-align: right;">
                <label id="lblReplacedBy" class="col-fixed formLabels" style="font-weight: 600; font-size: 14px;">
                  Replaced By</label>
              </div>

              <div class="col-lg-5 col-md-2 col-sm-6 mt-2 ">
                <span id="replacedBy" style="color: blue; text-decoration: underline; cursor: pointer; font-size: 14px;" (click)="navToPartInq()" id="navToPartInq">
                  {{generalpartEnquiryForm.get('replacedBy').value}}
                </span>
                
              </div>
            </div>
            <div class="row ">
              <div class="col-lg-7 col-md-2 col-sm-6  " style="text-align: right;">
                <label id="lblReplaces" class="col-fixed formLabels" style="font-weight: 600; font-size: 14px;">
                  Replaces</label>
              </div>

              <div class="col-lg-5 col-md-2 col-sm-6 mt-2 ">
                <span id="replaces" style="font-size: 14px;">
                  {{generalpartEnquiryForm.get('replacing').value}}
                </span>
                
              </div>
            </div>
            <div class="row ">
              <div class="col-lg-7 col-md-2 col-sm-6  " style="text-align: right;">
                <label id="lblmodelApplication" class="col-fixed formLabels" style="font-weight: 600; font-size: 14px;">
                  Model
                  Application</label>
              </div>

              <div class="col-lg-5 col-md-2 col-sm-6 mt-2 ">
                <span id="modelApplication" style="word-wrap: break-word; font-size: 14px;">
                  {{generalpartEnquiryForm.get('modelApplication').value}}
                </span>
                
              </div>
            </div>
          </div>  

          
            <div class="col-lg-4 col-md-2 col-sm-6">
              <div class="row " >
                <div class="col-lg-7 col-md-2 col-sm-6 " style="text-align: right;">
                  <label class="col-fixed formLabels" style="font-weight: 600; font-size: 14px;"
                  id="lblavailable">Available</label>
                </div>
  
                <div class="col-lg-5 col-md-2 col-sm-6 mt-2" >
                  <span id="available" style="font-size: 14px;">
                    {{generalpartEnquiryForm.get('available').value}}
                  </span>
                  
                </div>
              </div>
              <div class="row " >
                <div class="col-lg-7 col-md-2 col-sm-6 " style="text-align: right;">
                  <label id="lblUnitPackQty" class="col-fixed formLabels" style="font-weight: 600; font-size: 14px;">
                    Unit Pack</label>
                </div>
  
                <div class="col-lg-5 col-md-2 col-sm-6 mt-2 " >
                  <span id="unitpackQty" style="font-size: 14px;">
                    {{generalpartEnquiryForm.get('unitpackQty').value}}
                  </span>
                  
                </div>
              </div>
              <div class="row " *hasRole="['PANDA:PartInquiry:WithPriceData']" >
                <div class="col-lg-7 col-md-2 col-sm-6 " style="text-align: right;">
                  <label id="lblDealerPrice" class="col-fixed formLabels" style="font-weight: 600; font-size: 14px;">
                    Distributor Price</label>
                </div>
  
                <div class="col-lg-5 col-md-2 col-sm-6 mt-2 " >
                  <span id="dealerPrice" style="font-size: 14px;">
                    {{generalpartEnquiryForm.get('dealerPrice').value}}
                  </span>
                  
                </div>
              </div>
              <div class="row " *hasRole="['PANDA:PartInquiry:WithPriceData']" >
                <div class="col-lg-7 col-md-2 col-sm-6 " style="text-align: right;">
                  <label id="lblVAT" class="col-fixed formLabels" style="font-weight: 600; font-size: 14px;">
                    VAT/TAX/GST</label>
                </div>
  
                <div class="col-lg-5 col-md-2 col-sm-6 mt-2" >
                  <span id="vat" style="font-size: 14px;">
                    {{generalpartEnquiryForm.get('vat').value}}
                  </span>
                  
                </div>
              </div>
             
                
            </div>
            
            
        </div>
      </div>
        
      </mat-card-content>
    </mat-card>
  </form>
</div>


<!-- MOBILE VIEW -->
<div style="min-height: 88vh; " id="mobile">

  <form [formGroup]="generalpartEnquiryForm" style="padding: 0%;">
    <mat-grid-tile style="background-image: url('../../assets/image/mache1.jpg');">
      <mat-card class="basecard ">
        <!-- <mat-card-header *ngIf="partTab" class="mb-2  mat-elevation-z8" style="background:#3865a1; color: white;">

          <mat-card-title>
            <mat-icon  (click)="searchOrder()"  style="margin-top: 13px;font-size: 30px;">keyboard_arrow_left</mat-icon>
            <mat-icon style="padding-top: 10px; " id="titleLable"></mat-icon>PART INQUIRYss</mat-card-title>
        </mat-card-header> -->
        <div class="flex pb-2 mb-2  HeadTitle ">

          <div class="mr-2 ml-2">
            <mat-icon class="backIcon" *ngIf="backActive"(click)="searchOrder()" style="cursor: pointer; font-size: 30px; vertical-align: middle; color: white;"
              id="btnBack">keyboard_arrow_left
            </mat-icon>
            <mat-icon class="titleIcon" style="vertical-align: middle;"> handyman</mat-icon>
          </div>

          <div class="mr-2  orderHead">
            <span style="font-size: 22px; font-weight: 500; color: white; letter-spacing: 0.5px;">
              PART INQUIRY
            </span>
            </div>
            <div class="flex-grow-1"></div> <!-- This will push the following span to the right -->
            <div class="mr-2 orderHead" >
                <span>{{ this.utcDate }}</span>
            </div>
        </div>
		  <div class="formgrid p-grid mob">
			  <mat-accordion *ngIf="showFilter">

				  <mat-expansion-panel [expanded]="true"
									   (opened)="panelOpenState = true"
									   (closed)="panelOpenState = false" >
					  <mat-expansion-panel-header style="height: 35px !important;">
						  <mat-panel-title> Filter Options </mat-panel-title>
					  </mat-expansion-panel-header>

					  <div class="field col-12 col-md-4 divbody ">
						  <input class="form-control m-input" id="partHistory" type="text"
								 formControlName="partNumber" placeholder="Part Number" (keyup)="formatPartNumber()"
								 pInputText>
              <small id="username2-help" class="errorMessage" style="color: red; font-size: 13px ;font-weight: 500;" *ngIf="showErrorMsg">Please enter Part Number</small>
             
              
					  </div>


					  <div class="flex justify-content-center">
						  <div class="mr-2"> <button pButton id="btnSearch" type="button" label="Search" icon="pi pi-search"
													   class="btn-Search mb-2 mr-2 " (click)="submit()"
													   (keydown.enter)="submit()" (keyup.enter)="submit()"></button></div>
						  <div class="mr-2"><button pButton id="btnClear" type="button" label="Clear" icon="pi pi-times"
													  class="btn-clear mb-2" (click)="clear()"></button></div>

					  </div>

				  </mat-expansion-panel>
			  </mat-accordion>

		  </div>
<div>
  <small id="username2-help" class="errorMessage" style="color: red; font-size: 13px ;font-weight: 500;" *ngIf="showInvalidError">No Records Found. Please check the search criteria</small>
</div>
        <mat-card-content *ngIf="enablePartDetail">
          
			<div class="flex   mb-2" *ngIf="showmobFilter" >
				<button pButton label="Filter" class="mt-2 filterIcon"
						icon="pi pi-filter" (click)="showFilterOpt()"></button>
			</div>
              <div class="flex justify-content-between">
                <div><label  class="col-fixed formLabels"
                  id="lblpartNumber">Part Number :{{generalpartEnquiryForm.get('partNumber').value | uppercase}}</label></div>
                <div class="mt-2"> <span
                  style="background-color: #3865a1 ; border-radius: 6px ; padding: 5px;
                   font-size: 14px;color: white; cursor: auto;">{{generalpartEnquiryForm.get('inventoryStatus').value}}</span></div>
        <div class="mt-2"> <span *ngIf="backOrderVal"
          style="background-color: #3865a1 ; border-radius: 6px ; padding: 5px;
           font-size: 14px;color: white; cursor: auto;margin-left: 1%;">B/O</span></div>
        </div>

              <div class="flex justify-content-between">
                <div>  <label  class="col-fixed formLabels"
                  id="lblpartNumber">Restriction : No</label></div>
                
            </div>
          <mat-tab-group class="demo-tab-group">
            <mat-tab label="General Details">
              <div class="flex flex-column flex-md-row">
                <div class="mr-4  left-right">
                  <div class="field p-grid divbody" >
                    <label style="width:42%;justify-content: right" class="col-fixed formLabels"
                      id="lblpartNumber">Engineering Part</label>
                    <div class="col">
                      <span id="engineeringPartNo" style="font-size: 14px;">
                        {{generalpartEnquiryForm.get('engineeringPartNo').value}}
                      </span>

                    </div>
                  </div>
                  <div class="field p-grid divbody" >
                    <label id="lbldescription" style="width:42%;justify-content: right" class="col-fixed formLabels">
                      Description</label>
                      <div class="col">
                      <span id="desc" style="font-size: 14px;">
                        {{generalpartEnquiryForm.get('desc').value}}
                      </span>
                    </div>
                  </div>
                  <div class="field p-grid divbody">
                    <label id="lblFINIS" style="width:42%;justify-content: right" class="col-fixed formLabels">
                      FINIS</label>
                      <div class="col">
                      <span id="finis">
                        {{generalpartEnquiryForm.get('finis').value}}
                      </span>
                    </div>
                  </div>
                  <div class="field p-grid divbody">
                    <label id="lblProcurementCode" style="width:42%;justify-content: right" class="col-fixed formLabels">
                      Procurement Code</label>
                      <div class="col">
                      <span id="procurementCode">
                        {{generalpartEnquiryForm.get('procurementCode').value}}
                      </span>
                    </div>
                  </div>
                  <div class="field p-grid divbody">
                    <label id="lblReplacedBy" style="width:42%;justify-content: right" class="col-fixed formLabels">
                      Replaced By</label>
                      <div class="col">
                      <span id="replacedBy" style="color: blue;text-decoration: underline;cursor: pointer;" (click)="navToPartInq()" id="navToPartInq">
                        {{generalpartEnquiryForm.get('replacedBy').value}}
                      </span>
                    </div>
                  </div>
                  <div class="field p-grid divbody">
                    <label id="lblReplaces" style="width:42%;justify-content: right" class="col-fixed formLabels">
                      Replaces</label>
                      <div class="col">
                        <span id="replaces"  (click)="navToReplacePartInq()">
                          {{generalpartEnquiryForm.get('replacing').value}}
                        </span>
                    </div>
                  </div>
                  <div class="field p-grid divbody" >
                    <label id="lblmodelApplication" style="width:42%;justify-content: right" class="col-fixed formLabels">
                      Model
                      Application</label>
                      <div class="col">
                      <span id="modelApplication">
                        {{generalpartEnquiryForm.get('modelApplication').value}}
                      </span>
                    </div>
                    </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Quantity & Price Details">
              <p-panel header="Quantity" style="padding-top: 0px !important;">
              <div class="flex flex-column flex-md-row">
                <div class=" mr-4  left-right">
                  <div class="field p-grid divbody" >
                    <label style="width: 42%;justify-content: right" class="col-fixed formLabels"
                  id="lblavailable">Available</label>
                    <div class="col">
                      <span id="available" style="font-size: 14px;">
                        {{generalpartEnquiryForm.get('available').value}}
                      </span>

                    </div>
                  </div>
                  <div class="field p-grid divbody" >
                    <label id="lblDepth" style="width: 42%;justify-content: right" class="col-fixed formLabels">
                      Depth</label>
                      <div class="col">
                        <span id="depth">
                          {{generalpartEnquiryForm.get('depth').value}}
                        </span>
                    </div>
                  </div>
                  <div class="field p-grid divbody" >
                    <label id="lblHeight" style="width: 42%;justify-content: right" class="col-fixed formLabels">
                      Height</label>
                      <div class="col">
                        <span id="height">
                          {{generalpartEnquiryForm.get('height').value}}
                        </span>
                    </div>
                  </div>
                  <div class="field p-grid divbody" >
                    <label id="lblWidth" style="width: 42%;justify-content: right" class="col-fixed formLabels">
                      Width</label>
                      <div class="col">
                        <span id="width">
                          {{generalpartEnquiryForm.get('width').value}}
                        </span>
                    </div>
                  </div>
                  <div class="field p-grid divbody">
                    <label id="lblWeight" style="width: 42%;justify-content: right" class="col-fixed formLabels">
                      Weight (kgs)</label>
                      <div class="col">
                        <span id="weight">
                          {{generalpartEnquiryForm.get('weight').value}}
                        </span>
                    </div>
                  </div>
                  <div class="field p-grid divbody" >
                    <label id="lblCybeMetrics" style="width: 42%;justify-content: right" class="col-fixed formLabels">
                      Cube (m)</label>
                      <div class="col">
                        <span id="unitcube">
                          {{generalpartEnquiryForm.get('unitcube').value}}
                        </span>
                    </div>
                    </div>
                    <div class="field p-grid divbody" >
                      <label id="lblOverPackQty" style="width: 42%;justify-content: right" class="col-fixed formLabels">
                        Pallet Qty</label>
                        <div class="col">
                          <span id="overpackQty">
                            {{generalpartEnquiryForm.get('overpackQty').value}}
                          </span>
                      </div>
                      </div>
                      <div class="field p-grid divbody">
                        <label id="lblUnitPackQty" style="width: 42%;justify-content: right" class="col-fixed formLabels">
                          Unit Pack</label>
                          <div class="col">
                            <span id="unitpackQty">
                              {{generalpartEnquiryForm.get('unitpackQty').value}}
                            </span>
                        </div>
                        </div>
                </div>
              </div>
              </p-panel>
              <p-panel  *hasRole="['PANDA:PartInquiry:WithPriceData']" header="Price">
                <div class="flex flex-column flex-md-row">
                  <div class="mr-4  left-right">
                    <div class="field p-grid divbody" >
                      <label id="lblDealerPrice" style="width:42%;justify-content: right" class="col-fixed formLabels">
                        Price</label>
                      <div class="col">
                        <span id="dealerPrice">
                          {{generalpartEnquiryForm.get('dealerPrice').value}}
                        </span>

                      </div>
                    </div>
<!--                    <div class="p-field p-grid divbody">-->
<!--                      <label id="lblMRP" style="width:35%;justify-content: right" class="p-col-fixed formLabels">-->
<!--                        Discount</label>-->
<!--                        <div class="p-col">-->
<!--                          <span id="discountCode">-->
<!--                            {{generalpartEnquiryForm.get('discountCode').value}} %-->
<!--                          </span>-->
<!--                      </div>-->
<!--                    </div>-->
                    <div class="field p-grid divbody" >
                      <label id="lblVAT" style="width:42%;justify-content: right" class="col-fixed formLabels">
                        VAT/TAX/GST</label>
                        <div class="col">
                          <span id="vat">
                            {{generalpartEnquiryForm.get('vat').value}}
                          </span>
                      </div>
                    </div>



                  </div>
                </div>
              </p-panel>

            </mat-tab>



          </mat-tab-group>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </form>
</div>
