<div style="min-height: 86vh; ">
    <form [formGroup]="partNumberSearch" style="padding: 0px;">
        <mat-grid-tile style="background-image: url('../../assets/image/mache1.jpg');">
            <mat-card class="basecard ">
                <div class="flex pb-2 mb-2  HeadTitle">
                    <div class="mr-2 ml-2">
                        <mat-icon class="titleIcon ml-2"> manage_search</mat-icon>
                    </div>
                    <div class="mr-2  orderHead">
                        <span>
                            PARTS SEARCH
                        </span> 
                    </div>
                    <div class="flex-grow-1"></div> <!-- This will push the following span to the right -->
                    <div class="mr-2 orderHead" >
                        <span>{{ this.utcDate }}</span>
                    </div>
                </div>
                <mat-card-content>
                    <!-- ** WEB FILTER ** -->
                    <div class="formgrid p-grid web shadow-6">
                        <div class="formgrid p-grid mt-2">
                            <div class="field col-12 col-md-3 divbody mt-2">
                                <label for="dealer" id="partLabel" class="formLabels">Search by Partial Part Number
                                </label>
                                <input class="form-control m-input" id="partNumber" (keydown.enter)="searchDealer()"
                                    (keyup)="removeSpace()"  type="text"
                                    formControlName="partNumber" pInputText>
                                <div>
                                    <small id="username2-help" class="errorMessage" id="errorMessage"
                                        *ngIf="showError">Please
                                        enter either Part Number or Description </small>
                                    <small id="username2-help" class="errorMessage" id="errorMessage"
                                        *ngIf="partNumberSearch.controls['partNumber'].hasError('minlength') && !showError">A minimum
                                        of 3 characters is required</small>


                                </div>



                            </div>
                            <div class="field col-12 col-md-3 divbody mt-2">
                                <label for="dealer" id="descriptionLabel" class="formLabels ">Search by
                                    Partial Description</label>
                                <input class="form-control m-input" id="description" (keydown.enter)="searchDealer()"
                                    (keyup.enter)="searchDealer()" (keyup)="removeSpace()"
                                    appInputRestriction="noSpecialChars" type="text" formControlName="description"
                                    pInputText>
                                <div>

                                    <small id="username2-help" class="errorMessage" id="errorMessage"
                                        *ngIf="partNumberSearch.controls['description'].hasError('minlength') && !showError">A minimum
                                        of 3 characters is required</small>


                                </div>
                            </div>
                            <div class="field col-12  col-md-3 divbody mt-2 ">
                                <div> <label for="dealer" id="model" class="formLabels ">Search by Model</label></div>
                                <p-multiSelect formControlName="model" [options]="modelCode" defaultLabel="Select Model"
                                    [resetFilterOnHide]=true optionValue="code" optionLabel="description">
                                </p-multiSelect>
                            </div>

                        </div>
                        <div class="formgrid p-grid">
                            <div class="field col-12 col-md-3 divbody ">
                                <mat-slide-toggle id="inexBackorder" color="primary" formControlName="inexBackorder" class="flex items-center">
                                    <span class="ml-1">Include Back Order</span>
                                </mat-slide-toggle>
                            </div>

                            <div class="field col-12 col-md-6 divbody ">
                                <mat-slide-toggle id="inexObsolete" color="primary" formControlName="inexObsolete" class="flex items-center">
                                    <span class="ml-1">Include Obsolete</span>
                                </mat-slide-toggle>
                            </div>
                        </div>

                        <div class="p-grid row justify-content-center">
                            <div class="col">

                            </div>
                            <div class="col"> </div>


                        </div>
                        <div class="flex justify-content-center">
                            <div class="mr-2"> <button pButton id="btnSearch" type="button" label="Search"
                                    icon="pi pi-search" class="btn-Search  mb-2 mr-2 " (click)="searchDealer()"
                                    (keydown.enter)="searchDealer()" (keyup.enter)="searchDealer()"></button></div>
                            <div class="mr-2"><button pButton id="btnClear" type="button" label="Clear"
                                    icon="pi pi-times" class="btn-clear  mb-2" (click)="clear()"></button></div>

                        </div>


                    </div>

                    <!-- ** MOBILE FILTER ** -->
                    <div id="mobDiv" class="mobFilterDiv">
                        <mat-accordion class="formgrid p-grid mob" *ngIf="showFilter">
                            <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true"
                                (closed)="panelOpenState = false">
                                <mat-expansion-panel-header style="height: 35px !important;">
                                    <mat-panel-title style="font-weight: 500;"> Filter Options </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="field col-12 col-md-4 divbody ">

                                    <input class="form-control m-input" id="partNumber" (keydown.enter)="searchDealer()"
                                        minlength="3" (keyup)="removeSpace()" 
                                        placeholder="Search by Partial Part Number" type="text" formControlName="partNumber"
                                        pInputText>
                                    <div>
                                        <small id="username2-help" class="errorMessage" id="errorMessage"
                                        *ngIf="partNumberSearch.controls['partNumber'].hasError('minlength') && !showError">A minimum
                                        of 3 characters is required</small>
                                    </div>
                                </div>
                                <div class="field col-12 col-md-4 divbody ">
                                    <input class="form-control m-input" id="description" type="text"
                                        (keyup)="removeSpace()" appInputRestriction="noSpecialChars" minlength="3"
                                        placeholder="Search by Partial Description" formControlName="description" pInputText>
                                    <small id="username2-help" class="errorMessage" id="errorMessage"
                                        *ngIf="showError">Please enter
                                        either Part Number or Description </small>
                                    <small id="username2-help" class="errorMessage" id="errorMessage"
                                        *ngIf="partNumberSearch.controls['description'].hasError('minlength') && !showError">A minimum
                                        of 3 characters is required</small>
                                </div>

                                <div class="field col-12 col-md-4 divbody">
                                    <p-multiSelect [options]="modelCode" defaultLabel="Search by Model"
                                        [resetFilterOnHide]=true appendTo="body" id="model" class="multiselect-custom"
                                        optionValue="code" optionLabel="description" formControlName="model">
                                    </p-multiSelect>

                                    <!-- <p-dropdown [options]="modelCode"  formControlName="model"  placeholder="Select a City" optionLabel="description"
	[showClear]="true"></p-dropdown>  -->


                                </div>
                                <div class="formgrid p-grid">
                                    <div class="field col-12 col-md-4 divbody ">
                                        <!-- <mat-slide-toggle color="primary" formControlName="inexBackorder" id="inexBackorder"
                                            style="margin-right:10px;font-size: 14px;">Include BackOrder
                                        </mat-slide-toggle>
                                        <mat-slide-toggle color="primary" formControlName="inexObsolete" id="inexObsolete"
                                            style="font-size:14px; font-family: sans-serif;">Include Obsolete
                                        </mat-slide-toggle> -->
                                        <div class="flex">
                                            <div class="mr-1">
                                                <p-inputSwitch formControlName="inexBackorder" id="inexBackorder">
                                                </p-inputSwitch>

                                            </div>
                                            <div class=" mobLabel">
                                                <span>Include BackOrder</span>
                                            </div>
                                            <div class="ml-2">
                                                <p-inputSwitch formControlName="inexObsolete" id="inexObsolete">
                                                </p-inputSwitch>

                                            </div>
                                            <div class="ml-2 mobLabel">
                                                <span>Include Obsolete</span>
                                            </div>

                                        </div>



                                    </div>
                                </div>
                                <div class="flex justify-content-center">
                                    <div class="mr-2"> <button pButton id="btnSearch" type="button" label="Search"
                                            icon="pi pi-search" class="btn-Search  mb-2 mr-2 " (click)="searchDealer()"
                                            (keydown.enter)="searchDealer()" (keyup.enter)="searchDealer()"></button>
                                    </div>
                                    <div class="mr-2"><button pButton id="btnClear" type="button" label="Clear"
                                            icon="pi pi-times" class="btn-clear  mb-2" (click)="clear()"></button></div>

                                </div>

                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>

                    <!-- *** WEB TABLE ** -->
                    <div class="ml-2 mt-2">
                        <small id="username2-help" class="errorMessage"
                            *ngIf="!showError && submitted && partList.length==0 " id="errorMessage">No Records Found.
                            Please check the search criteria. </small>
                    </div>
                    <div class="webTable  shadow-3" style="margin-top: 20px;" *ngIf="partList.length>0">
                        <p-table #dt1 [columns]="cols" [value]="partList" class="ml-2 mb-2 "
                            styleClass="p-datatable-responsive-demo" [lazy]="true" (onRowSelect)="onRowSelect($event)"
                            [globalFilterFields]="['partList.partNumber']" [loading]="loading"
                            (onLazyLoad)="loadOrderDetail($event)" [paginator]="true" [rows]="10" id="webTable"
                            [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [totalRecords]="totalRecords">



                            <ng-template pTemplate="header" let-columns>
                                <tr class="tablehead">
                                    <th pSortableColumn="partList.partNumber" id="partNumberSort" class="partNumberHeader" >
                                        Part Number <p-sortIcon field="partList.partNumber"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="partList.description" id="descriptionSort" class="descriptionHeader">Description
                                        <p-sortIcon field="partList.description"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="partList.availableQty" id="availableQtySort" class="availableQtyHeader">Available
                                        <!-- <p-sortIcon field="partList.availableQty"></p-sortIcon>-->
                                    </th>
                                    <th pSortableColumn="partList.model" id="modelSort"   class="modelHeader">Model
                                        <!--<p-sortIcon
                                            field="partList.model"></p-sortIcon>-->
                                    </th>
                                    <th pSortableColumn="partList.status" id="statusSort"  class="statusHeader" >Status
                                        <p-sortIcon field="partList.status"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns">
                                <tr style="font-size: 15px;">  

                                    <td style="text-align: left;">

                                        <!-- <button mat-icon-button [matMenuTriggerFor]="menu" id="btncontectMenu"
                                            aria-label="Example icon-button with a menu"
                                            (keydown.enter)="$event.preventDefault()">
                                            <mat-icon style="font-size: 20px; cursor: pointer;"
                                                (keydown.enter)="$event.preventDefault()">more_vert
                                            </mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="navToBackOrder(rowData)"
                                                id="btnbackorderMenu">
                                                <mat-icon>post_add</mat-icon>
                                                <span>BackOrder</span>
                                            </button>
                                            <button mat-menu-item (click)="navToSupersession(rowData)"
                                                id="btnSupersessionMenu">
                                                <mat-icon>add_task</mat-icon>
                                                <span>Supersession Inquiry</span>
                                            </button>
                                            </mat-menu> -->
                                            <p-menu #menu [popup]="true" appendTo="body" styleClass="menucus" [model]="items" > </p-menu>
                                            <mat-icon style="font-size: 16px; cursor: pointer; vertical-align: middle; display: inline-block;"
                                            (click)="menu.toggle($event);selectedrow(rowData)">more_vert
                                            </mat-icon>

                                        <a (click)="navToPartInq(rowData)" id="navToPartInq"
                                            style="cursor: pointer; text-align: center; color: blue; text-decoration: underline; vertical-align: middle; display: inline-block; margin-left: 5px;"> {{rowData.partNumber}}
                                        </a>
                                    </td>
                                    <td style="text-align: left;">{{rowData.description}}</td>
                                    <td style="text-align: center;">{{rowData.availableQty}}</td>
                                    <td style="text-align: left;word-wrap: break-word;">{{rowData.model}}</td>
                                    <td style="text-align: left;">{{rowData.status}}
                                        <!-- <button *ngIf="rowData.backOrder=='Y'" pButton pRipple type="button" label="B"
                                                style="background-color: #3865a1 ; border-radius: 10px ;  font-size: 14px; cursor: auto;"></button> -->
                                        <span *ngIf="rowData.backOrder=='Y'"
                                            style="background-color: #3865a1 ; border-radius: 6px ; padding: 5px; font-size: 14px;color: white; cursor: auto;">B/O</span>
                                    </td>

                                </tr>

                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="5">No records found.</td>
                                </tr>
                            </ng-template>


                        </p-table>
                    </div>
                    <!-- **** Mobile Table */*****       -->
                    <div class="mobTable tablestyle " *ngIf="partList.length>0">
                        <div class="flex mt-4 mb-2 flex justify-content-between" *ngIf="totalRecords!=0 && showmobFilter">
                            
                            <button pButton label="Filter" class="filterIcon" icon="pi pi-filter"
                                (click)="showFilterOpt()"></button>


                        </div>
                        <p-table #dt1 [columns]="cols" [value]="partList" styleClass="p-datatable-responsive-demo"
                            [lazy]="true" (onRowSelect)="onRowSelect($event)" [loading]="loading" id="mobTable"
                            (onLazyLoad)="loadOrderDetail($event)" [paginator]="true" [rows]="3"
                            [showCurrentPageReport]="true"
                            currentPageReportTemplate="{first} to {last} of {totalRecords}"
                            [globalFilterFields]="['partList.partNumber']" [totalRecords]="totalRecords"
                            *ngIf="totalRecords!=0">

                            <ng-template pTemplate="body" let-rowData let-columns="columns">

                                <tr class="mt-2  cardTable">

                                    <td>

                                        <span style="color: blue; text-decoration: underline; vertical-align: middle; display: inline-block;" (click)="navToPartInq(rowData)" id="navToPartInq"> {{rowData.partNumber}}
                                        </span>
                                       
                                        <p-menu #menu [popup]="true" appendTo="body" [model]="items"></p-menu>
                    
                    <mat-icon style="font-size: 18px; cursor: pointer; vertical-align: middle; display: inline-block;"
                    (click)="menu.toggle($event);selectedrow(rowData)">more_vert
                                            </mat-icon>

                                        <span style="float: right;"> {{rowData.description}} </span>
                                    </td>

                                    <td style="text-align: left;">{{rowData.availableQty}}</td>


                                    <td style="text-align: left;">
                                        <div class="flex text-center">
                                            <div style="margin-left: 5px; font-weight: 550;font-size: 15px;">
                                                <span>Status:</span>
                                            </div>
                                            <div class="mr-2" style="margin-left: 5px;">
                                                {{rowData.status}}
                                                <span *ngIf="rowData.backOrder=='Y'"
                                                    style="background-color: #3865a1 ; border-radius: 5px ; padding: 5px; font-size: 14px;color: white; cursor: auto;">B/O</span>

                                            </div>

                                        </div>


                                    </td>
                                    <td style="text-align: left;"> {{rowData.model }} </td>

                                </tr>
                            </ng-template>

                        </p-table>

                    </div>

                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
    </form>
</div>